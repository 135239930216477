import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'

const Vehicles = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />

        <main>
          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBRow>
            </MDBContainer>
          </section>
        </main>
      </Layout>
    </div>
  )
}
export default Vehicles

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query { 
    markdownRemark(frontmatter: { name: { eq: "contract-vehicles" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
      }
      html
    }
  }
`